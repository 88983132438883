import React from "react"
import { StoryProvider } from "../../context/story-context"
import StoryUI from "../../components/StoryUI"
import SEO from "../../components/root/SEO"
import FrontPage from "../../components/FrontPage"
import Header from "../../components/root/Header"
import { StoryDataProvider } from "../../context/story-data"

const Story = ({ params }) => {
  return (
    <StoryDataProvider isPrivate={true} uid={params.uid}>
      <StoryProvider>
        <SEO title="Home" description="DigitalFootprint.earth" />
        <div className="w-screen h-screen relative">
          <div className="absolute w-screen top-0 left-0 z-40">
            <Header isLanding />
          </div>
          <div className="absolute top-0 left-0 z-0">
            <FrontPage noInputs={true}>
              <StoryUI />
            </FrontPage>
          </div>
        </div>
      </StoryProvider>
    </StoryDataProvider>
  )
}

export default Story
